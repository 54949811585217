import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'logo_vk_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="logo_vk_24"><path clip-rule="evenodd" d="M4.265 4.265C3 5.531 3 7.567 3 11.64v.72c0 4.073 0 6.11 1.265 7.375S7.567 21 11.64 21h.72c4.073 0 6.11 0 7.375-1.265S21 16.433 21 12.36v-.72c0-4.073 0-6.11-1.265-7.375S16.433 3 12.36 3h-.72C7.567 3 5.53 3 4.265 4.265zM6 8.5c.096 4.682 2.556 7.5 6.61 7.5h.235v-2.679c1.476.15 2.577 1.254 3.026 2.679H18c-.578-2.132-2.075-3.31-3.005-3.76.93-.558 2.246-1.908 2.556-3.74h-1.937c-.406 1.49-1.615 2.84-2.77 2.968V8.5h-1.968v5.197c-1.197-.3-2.759-1.757-2.823-5.197z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24LogoVkProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24LogoVk: FC<Icon24LogoVkProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24LogoVk as any).mountIcon = mountIcon;

export default Icon24LogoVk;
